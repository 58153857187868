<template>
  <div class="about-wrapper fade-in">
    <div class="about">
      <div class="about-text">ANViL is a Character Generator for the First Edition of the Pathfinder Roleplaying Game.</div>
      <div class="about-text">It is the successor to the Trove Tokens 3.75 Character Generator, a Flash web application which, over the course of nearly two decades, allowed tens of thousands of Pathfinder players to create and maintain <i>a more-than-tens-of-thousands number</i> of characters.</div>
      <div class="about-text">The death of Flash brought an unceremonious end to the heady trappings of that era.</div>
      <div class="about-text">Now, from the ashes, ANViL bears forth the embers of change, bringing hope to 1st Edition Pathfinder players everywhere, and ushering in a new age of prosperity and character generation, the likes of which have never before deigned to touch the human imagination.</div>
      <div class="about-text">As the tides of time carry us hence, bouyed upon their everflowing streams, may the fates inspire you to join us on this <a href="https://patreon.com/treasuretrove" target="_blank">epic quest</a>.</div>
      <div class="about-text">ANViL is free to use, but its premium features are reserved for our Patreon partners, who have pledged to assist with the generator's ongoing development. You can <a href="https://patreon.com/treasuretrove" target="_blank">become a sponsor</a>.</div>
      <div class="about-text" v-if="corePatrons.length > 0 || prestigePatrons.length > 0 || epicPatrons.length > 0">The following <a href="https://www.patreon.com/join/treasuretrove/checkout?rid=5977629" target="_blank">patrons</a> have gone above and beyond the call, demonstrating exemplary valor, good-looking-ness, and heart through their support and dedication in bringing the ANViL project to life and furthering its progress:</div>
      <div class="patrons-display">
        <h2>Epic Patrons</h2>
        <div class="patron-wrapper">
          <div class="patron" v-for="(patron, index) in epicPatrons" :key="index">
            {{ patron }}
          </div>
        </div>
        <h2>Prestige Patrons</h2>
        <div class="patron-wrapper">
          <div class="patron" v-for="(patron, index) in prestigePatrons" :key="index">
            {{ patron }}
          </div>
        </div>
        <h2>Core Patrons</h2>
        <div class="patron-wrapper">
          <div class="patron" v-for="(patron, index) in corePatrons" :key="index">
            {{ patron }}
          </div>
        </div>
      </div>

      <div class="about-text">Got a feature to request or a bug to report? <a href="mailto:treasuretrovesoft@gmail.com">Hit us up</a>.</div>
      <hr>
      <div class="paizo">
        <p class="boilerplate">Pathfinder and associated marks and logos are trademarks of Paizo Publishing, LLC, and are used under license. See <a href="http://www.paizo.com/pathfinderRPG">paizo.com/pathfinderRPG</a> for more information on the Pathfinder Roleplaying Game.</p>
        <p class="boilerplate">Compatibility with the Pathfinder Roleplaying Game requires the Pathfinder Roleplaying Game from Paizo Publishing, LLC. See <a href="http://paizo.com/pathfinderRPG" target="_blank">http://paizo.com/pathfinderRPG</a> for more information on the Pathfinder Roleplaying Game. Paizo Publishing, LLC does not guarantee compatibility, and does not endorse this product.</p>
        <p class="boilerplate">Pathfinder is a registered trademark of Paizo Publishing, LLC, and the Pathfinder Roleplaying Game and the Pathfinder Roleplaying Game Compatibility Logo are trademarks of Paizo Publishing, LLC, and are used under the Pathfinder Roleplaying Game Compatibility License. See <a href="http://paizo.com/pathfinderRPG/compatibility" target="_blank">http://paizo.com/pathfinderRPG/compatibility</a> for more information on the compatibility license.</p>
        <p class="boilerplate"><a href="http://www.wizards.com/d20/files/oglv1.0a.rtf" target="_blank">Open Game License v 1.0 Copyright 2000, Wizards of the Coast, Inc.</a><br>
        System Reference Document. Copyright 2000, Wizards of the Coast, Inc.; Authors Jonathan Tweet, Monte Cook, Skip Williams, based on material by E. Gary Gygax and Dave Arneson.<br>
        Pathfinder RPG Core Rulebook. Copyright 2009, Paizo Publishing, LLC; Author: Jason Bulmahn, based on material by Jonathan Tweet, Monte Cook, and Skip Williams.<br>
        Pathfinder RPG Advanced Players Guide. Copyright 2010, Paizo Publishing, LLC; Author: Jason Bulmahn.<br>
        Pathfinder RPG Ultimate Magic. Copyright 2011, Paizo Publishing, LLC; Authors: Jason Bulmahn, Tim Hitchcock, Colin McComb, Rob McCreary, Jason Nelson, Stephen Radney-MacFarland, Sean K Reynolds, Owen K.C. Stephens, and Russ Taylor.<br>
        Pathfinder RPG Ultimate Combat. Copyright 2011, Paizo Publishing, LLC; Authors: Dennis Baker, Jesse Benner, Benjamin Bruck, Jason Bulmahn, Brian J. Cortijo, Jim Groves, Tim Hitchcock, Richard A. Hunt, Colin McComb, Jason Nelson, Tom Phillips, Patrick Renie, Sean K Reynolds, and Russ Taylor.<br>
        The Book of Experimental Might. Copyright 2008, Monte J. Cook. All rights reserved.<br>
        Tome of Horrors. Copyright 2002, Necromancer Games, Inc.; Authors: Scott Greene, with Clark Peterson, Erica Balsley, Kevin Baase, Casey Christofferson, Lance Hawvermale, Travis Hawvermale, Patrick Lawinger, and Bill Webb; Based on original content from TSR.<br>

        ANViL - 1st Edition Pathfinder Character Generator. Copyright 2020-{{ currentYear }}, Treasure Trove Software. All rights reserved.<br>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { APP_TITLE } from '../constants/metaConstants';

export default {
  title: `About ${APP_TITLE}`,
  created() {
    this.$store.dispatch('fetchPatrons');
  },
  computed: {
    ...mapState([
      'corePatrons',
      'prestigePatrons',
      'epicPatrons',
    ]),
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.about-wrapper {
  .about {
    padding: 3rem 5rem;
    max-width: 1140px;
    margin: 0 auto;
    .about-text {
      font-size: 1.25rem;
      padding: 1rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      font-weight: $semi-bold-text;
      margin-top: 0;
    }
    .patrons-display {
      margin: 3rem 0;
      h2 {
        font-weight: $semi-bold-text;
        letter-spacing: 0.25rem;
      }
      .patron-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        .patron {
          font-size: $text-med-large;
          font-weight: $bold-text;
          padding: 0.5rem 1rem;
        }
      }
    }
    .paizo {
      margin-top: 30px;
      .boilerplate {
        font-size: $text-tiny;
      }
    }
  }
}

</style>
